import { Actions } from '../types/actions';
import { CHANGE_EXCEL_DOCUMENT, CHANGE_INTERNAL_PRICE, CHANGE_PDF_DOCUMENT } from '../types/constants';

export const changeInternalPriceAction = (internalPrice: boolean): Actions => ({
  type: CHANGE_INTERNAL_PRICE,
  internalPrice,
});

export const changePdfDocument = (file: FileList): Actions => ({
  type: CHANGE_PDF_DOCUMENT,
  file,
});

export const changeExcelDocument = (file: FileList): Actions => ({
  type: CHANGE_EXCEL_DOCUMENT,
  file,
});
