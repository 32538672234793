import React, { FC } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Wizard from './wizard';

const Container: FC = () => {
  return (
    <div className="application">
      <h1 className="application__title">MAN Trucks</h1>
      <Router>
        <Switch>
          <Route path="/">
            <Wizard url="/" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default Container;
