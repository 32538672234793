import { Actions } from '../../types/actions';
import { CHANGE_EXCEL_DOCUMENT } from '../../types/constants';

const initialState: FileList = {} as FileList;

const excel = (state = initialState, action: Actions): FileList => {
  switch (action.type) {
    case CHANGE_EXCEL_DOCUMENT:
      return action.file;
    default:
      return state;
  }
};

export default excel;
