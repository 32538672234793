import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { WizardProps } from '../../types/props';
import { State } from '../../types/states';
import offerWizard from '../create-offer-wizard';

const Wizard: FC<WizardProps> = (props: WizardProps) => {
  const { url } = props;
  const offer = useSelector((state: State) => state.offer);
  return (
    <div className="wizard">
      <Switch>
        <Route path={`${url}`} exact={true} component={offerWizard.Step1} />
      </Switch>
      {offer.path.trim().length > 0 && (
        <a href={offer.path} download target="_blank" rel="noopener noreferrer">
          Download
        </a>
      )}
    </div>
  );
};

export default Wizard;
