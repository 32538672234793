import api from '../api';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, all, select, put } from 'redux-saga/effects';
import { REQUEST_UPLOAD } from '../types/constants';
import { State } from '../types/states';
import { receiveUpload } from '../actions';

function* uploadFile(): SagaIterator {
  const state: State = yield select();
  const files: File[] = [];
  console.log(state.offer.pdf);
  if (state.offer.pdf.length > 0) {
    for (let i = 0; i < state.offer.pdf.length; i++) {
      files.push(state.offer.pdf[i]);
    }

    for (let i = 0; i < state.offer.excel.length; i++) {
      files.push(state.offer.excel[i]);
    }

    yield call(api.uploadFile, files, state.offer.internalPrice ? 1 : 0);
    yield put(receiveUpload());
  }
}

function* watchRequestFile(): SagaIterator {
  yield takeLatest(REQUEST_UPLOAD, uploadFile);
}

export default function* uploadSaga(): unknown {
  yield all([watchRequestFile()]);
}
