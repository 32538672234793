import { Actions } from '../types/actions';
import { RECEIVE_UPLOAD, REQUEST_UPLOAD } from '../types/constants';

export const requestUpload = (): Actions => ({
  type: REQUEST_UPLOAD,
});

export const receiveUpload = (): Actions => ({
  type: RECEIVE_UPLOAD,
});
