import { Actions } from '../../types/actions';
import { CHANGE_PDF_DOCUMENT } from '../../types/constants';

const initialState: FileList = {} as FileList;

const pdf = (state = initialState, action: Actions): FileList => {
  switch (action.type) {
    case CHANGE_PDF_DOCUMENT:
      return action.file;
    default:
      return state;
  }
};

export default pdf;
