export const INITIALIZE_APPLICATION = `INITIALIZE_APPLICATION`;

export const CHANGE_INTERNAL_PRICE = `CHANGE_INTERNAL_PRICE`;

export const REQUEST_UPLOAD = `REQUEST_UPLOAD`;
export const RECEIVE_UPLOAD = `RECEIVE_UPLOAD`;
export const ERROR_UPLOAD = `ERROR_UPLOAD`;

export const CHANGE_PDF_DOCUMENT = `CHANGE_PDF_DOCUMENT`;
export const CHANGE_EXCEL_DOCUMENT = `CHANGE_EXCEL_DOCUMENT`;
