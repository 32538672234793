import handleFileUpload from './utils';

export async function uploadFile(files: File[], internalPrice?: number): Promise<void> {
  return await handleFileUpload(
    `/api/file/upload/${typeof internalPrice === 'undefined' ? 0 : internalPrice}`,
    'POST',
    files,
  );
}

export default { uploadFile };
