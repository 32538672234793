import { Actions } from '../../types/actions';
import { REQUEST_UPLOAD, RECEIVE_UPLOAD } from '../../types/constants';
const initialState = false;

const loading = (state = initialState, action: Actions): boolean => {
  switch (action.type) {
    case REQUEST_UPLOAD:
      return true;
    case RECEIVE_UPLOAD:
      return false;
    default:
      return state;
  }
};

export default loading;
