import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeExcelDocument, changeInternalPriceAction, changePdfDocument, requestUpload } from '../../actions';
import UploadSection from '../../components/UploadSection';
import Checkbox from '../../components/Checkbox';
import { State } from '../../types/states';

const Step1: FC = () => {
  const offer = useSelector((state: State) => state.offer);
  const dispatch = useDispatch();
  const title = `Bestand uploaden`;
  const description = `Voeg offertebestand toe.`;
  // const excelDescription = `Kies hier uw niet opgemaakte pdf-bestand.<br/> Daarnaast hebt u ook nog de optie om uw calculatieblad te uploaden.`;

  const handlePdfFileChange = (files: FileList | null): void => {
    if (files && files?.length > 0) {
      dispatch(changePdfDocument(files));
    }
  };

  const handleExcelFileChange = (files: FileList | null): void => {
    if (files && files?.length > 0) {
      dispatch(changeExcelDocument(files));
    }
  };

  const handleSelectChange = (selected: boolean): void => {
    dispatch(changeInternalPriceAction(selected));
  };

  return offer.loading ? (
    <div className="step__loading" />
  ) : (
    <>
      <div className="step">
        <div className="step__title-section">
          <h1 className="step__title">{title}</h1>
        </div>
        <div className="step__description" dangerouslySetInnerHTML={{ __html: description }} />
        <div className="step__content">
          <UploadSection
            handleFileChange={handlePdfFileChange}
            accept=".pdf"
            modifier="pdf"
            containsFile={Object.keys(offer.pdf).length > 0}
            uploadText={`Sleep uw document naar hier<br />of<br /><a>bladeren op uw computer</a>`}
          />
          <UploadSection
            handleFileChange={handleExcelFileChange}
            accept=".xls,.xlsx,.xlsm"
            modifier="excel"
            containsFile={Object.keys(offer.excel).length > 0}
            uploadText={`Kies hier het gemaakte calculatieblad`}
          />
          <Checkbox
            handleSelectChange={handleSelectChange}
            id="internalPrice"
            label="Interne prijs"
            name="internalPrice"
            selected={offer.internalPrice}
          />
        </div>
      </div>
      <div className="step__actions">
        <button
          className={'button button--primary' + (Object.keys(offer.pdf).length === 0 ? ' button--invalid' : '')}
          onClick={(): void => {
            if (Object.keys(offer.pdf).length > 0) {
              dispatch(requestUpload());
            }
          }}
        >
          Document opmaken!
        </button>
      </div>
    </>
  );
};

export default Step1;
