import { combineReducers } from 'redux';
import initialized from './initialized';
import offer from './offer';
import { State } from '../types/states';

const rootReducers = combineReducers<State>({
  initialized,
  offer,
});

export default rootReducers;
