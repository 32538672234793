import { Actions } from '../../types/actions';
import { INITIALIZE_APPLICATION } from '../../types/constants';
const initialState = false;

const initialized = (state = initialState, action: Actions): boolean => {
  switch (action.type) {
    case INITIALIZE_APPLICATION:
      return true;
    default:
      return state;
  }
};

export default initialized;
