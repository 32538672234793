import download from 'downloadjs';

const handleFileUpload = (
  action: RequestInfo,
  method: string,
  files: File[],
  authentication?: string,
): Promise<void> => {
  const request: RequestInit = {
    method: method,
    headers: {
      Authorization: typeof authentication !== 'undefined' && authentication !== null ? `Bearer ${authentication}` : '',
    },
  };
  if (typeof files !== 'undefined' && files.length > 0) {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    request.body = formData;
  }
  return new Promise((resolve, reject) => {
    fetch(action, request).then((response) => {
      if (response.status === 401) {
        reject(new Error('Unauthorized'));
      }
      response.blob().then((blob) => download(blob, files[0].name));

      resolve();
    });
  });
};

export default handleFileUpload;
