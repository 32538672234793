import { Actions } from '../../types/actions';
import { INITIALIZE_APPLICATION } from '../../types/constants';
const initialState = '';

const path = (state = initialState, action: Actions): string => {
  switch (action.type) {
    case INITIALIZE_APPLICATION:
      return initialState;
    default:
      return state;
  }
};

export default path;
