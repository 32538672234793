import { combineReducers } from 'redux';
import path from './path';
import internalPrice from './internal-price';
import pdf from './pdf';
import excel from './excel';
import loading from './loading';
import { Offer } from '../../types/states';

const offerReducers = combineReducers<Offer>({
  internalPrice,
  path,
  pdf,
  excel,
  loading,
});

export default offerReducers;
