import React, { FC } from 'react';
import { CheckboxProps } from '../types/props';

const Checkbox: FC<CheckboxProps> = (props: CheckboxProps) => {
  const { handleSelectChange, id, name, label, selected } = props;

  return (
    <div className="checkbox">
      <label htmlFor={id} className="checkbox__label">
        <input
          type="checkbox"
          className="checkbox__input"
          name={name}
          id={id}
          checked={selected}
          onChange={(): void => handleSelectChange(!selected)}
        />
        <span className="checkbox__copy" dangerouslySetInnerHTML={{ __html: label }} />
      </label>
    </div>
  );
};

export default Checkbox;
